.work-section {
    transition: background-color 0.3s, margin-top 0.3s, margin-bottom 0.3s, padding 0.3s;
    padding: 0 var(--margin-left-right) 0 var(--margin-left-right);
}

.work-section-open {
    background-color: var(--bs-primary);
    /*background-color: #f7f3f1;*/
    margin-top: 12px;
    margin-bottom: 100px;
    padding: 0 0 0 var(--margin-left-right);
}

/*.work-section-open * {*/
/*    color: black !important;*/
/*}*/

.card-item-text {
}

.item-card {
    background: transparent !important;
    width: 100%;
}

/*.item-card img {*/
/*    width: 100%; !* image box size as % of container, see step 1 *!*/
/*    height: 100%; !* image box size as % of container, see step 1 *!*/
/*    object-fit: cover; !* matching of image pixels to image box, see step 2 *!*/
/*}*/

.work-title {
    transition: transform 0.3s;
}

.work-title-open {
    /*transform: translateY(42px);*/
}

.work-title-name {
    font-size: max(48px, 6vw);
    font-weight: 500;
    line-height: 0.9;
    font-family: var(--font-title), sans-serif;
    letter-spacing: max(-1.5px, -0.16vw);
}

.work-title-year {
    font-family: var(--bs-font-sans-serif), sans-serif;
    font-weight: 300;
    font-size: max(10px, 0.9vw);
    line-height: 1;
    letter-spacing: max(0.4px, 0.07vw);
    margin-bottom: max(4px, 0.5vw);
    /*transition: opacity 0.3s;*/
}

/*.work-title-year-open {*/
/*    opacity: 0;*/
/*}*/

.work-img {
    /*opacity: 0.5;*/
}

.work-row {
    gap: 2.5vw;
    display: flex;
    flex-direction: row;
    /*overflow: hidden;*/
}

.work-row-2 {
    height: 0;
    overflow: hidden;
    transition: height 0.3s, margin-bottom 0.3s, margin-top 0.3s;
}

.work-row-2-open {
    margin-bottom: 48px;
    margin-top: 48px;
}

/*.work-row-close {*/
/*    !*max-height: 0;*!*/
/*    height: 0;*/
/*}*/

/*.work-row-open {*/
/*    !*max-height: 100%;*!*/
/*    height: auto;*/
/*}*/

.work-left {
    /*border-bottom: solid 1px;*/
    border-bottom-color: rgba(17, 17, 17, 0.2);
    /*border-bottom-color: rgba(250, 250, 250, 0.2);*/
    padding-bottom: calc(1.7vw + 5px);
    transition: border-bottom-color 0.3s, padding-bottom 0.3s;
}

.work-left-open {
    border-bottom-color: var(--bs-primary-trans);
    padding-bottom: 0;
}

.work-left,
.work-empty {
    /*width: 30vw;*/
}

.work-desc {
    gap: max(5px, min(6vw, 9vh));
    /*transition: width 0.3s;*/
}

.work-desc-open {
    /*width: 150%;*/
}

.work-type-text {
    justify-content: end;
    font-family: "Instrument Serif", "Bacasime Antique", serif;
    font-size: max(18px, 1.7vw);
    /*letter-spacing: max(-0.5px, min(-0.14vw, -0.21vh));*/
    line-height: 1;
}

.work-type-block {
    transition: transform 0.3s;
}

.work-type-line {
    margin-top: max(3px, min(0.4vw, 0.6vh));
}

.work-type-block-open {
    transform: translateY(calc(100% + 48px)) !important;
}

.work-desc-text {
    justify-content: end;
    text-align: right;
    font-weight: 500;
    transition: flex 0.3s, width 0.3s;
    /*flex: 1;*/
}

/*.work-desc-text-close {*/
/*    flex: 1;*/
/*    !*width: auto;*!*/
/*}*/

/*.work-desc-text-open {*/
/*    flex: 0;*/
/*    !*width: 0;*!*/
/*}*/

.work-desc-segment {
    /*border-top: solid 1px;*/
    overflow: hidden;
    font-weight: 300;
    margin-bottom: 20px;
    width: 85%;
    max-width: 800px;
    /*max-width: 400px;*/
    /*min-width: 100%;*/
    /*transition: opacity 0.3s, max-height 0.3s;*/
}

.work-desc-segment-close {
    opacity: 1;
    height: fit-content;
    transition: opacity 0.3s 0.3s, max-height 0.3s;
}

.work-desc-segment-open {
    border-top-color: rgba(255, 255, 255, 0);
    opacity: 0;
    height: 0;
    /*transition: opacity 0s ease;*/
    /*display: none;*/
    transition: max-height 0.3s;
}

/*.work-desc-segment-close {*/
/*    border-top-color: white;*/
/*    !*transition: height 0.3s, border-top-width 0.3s ease 0s !important;*!*/
/*}*/

.work-desc-line {
    /*margin-left: 20px;*/
    /*padding-top: 26px;*/
    font-size: max(12px, 0.9vw);
    line-height: 1.1;
    letter-spacing: max(-0.3px, min(-0.4vw, -0.6vh));
    text-align: left;
    font-weight: 400;
}

.work-content {
    padding-bottom: calc(1.7vw + 5px);
    transition: padding-bottom 0.3s, flex 0.3s;
}

.work-right-close {
    flex: 1.5;
}

.work-right-open {
    flex: 2.5;
    padding-bottom: 0 !important;
}

/*.work-content-open {*/
/*    padding-bottom: 0;*/
/*}*/

.work-detail {
    transition: flex 0.3s;
}

.work-detail {
    /*padding-top: 48px;*/
    /*padding-bottom: 48px;*/
    font-size: max(14px, min(1.2vw, 1.8vh));
    /*line-height: 1.1;*/
    letter-spacing: max(-0.4px, min(-0.7vw, -0.11vh));
    /*text-align: left;*/
    font-weight: 400;
    display: flex;
    flex-direction: row;
}

.work-detail-col {
    /*padding-right: 15%;*/
    /*padding-bottom: 48px;*/
    /*padding-top: 48px;*/
    width: 24vw;
    gap: 36px;
}

.work-detail > div > div {
    /*margin-bottom: 20px;*/
}

.work-detail-right-right {
    height: fit-content;
}

.work-detail-right-right td {
    align-content: start;
    padding-bottom: 20px;
    font-weight: 300;
}

.work-detail-right-right th {
    white-space: nowrap;
    padding-right: 20px;
    /*padding-bottom: 0px;*/
}

/*.work-detail-right-right tr {*/
/*    row-gap: 10px;*/
/*}*/

@media (max-width: 680px) {
    .work-row {
        gap: 12px;
        flex-direction: column;
    }

    .work-left {
        width: 100%;
        /*min-height: 150px;*/
    }

    .work-desc-line {
        padding-top: 12px;
    }

    .work-section {
        padding: 0 var(--margin-left-right);
    }

    .work-title-open {
        transform: none;
    }

    .work-type-block-open {
        transform: none !important;
    }

    .work-section-open {
        padding-top: 24px;
        margin-bottom: 70px;
    }

    .work-detail {
        flex-direction: column-reverse;
    }

    .work-detail-col {
        /*padding-right: 15%;*/
        /*padding-bottom: 24px;*/
        /*padding-top: 24px;*/
        width: auto;
        gap: 36px;
    }

    .work-row-2-open {
        margin-bottom: 24px;
        margin-top: 24px;
    }
}

@media (max-width: 1200px) {
    .work-desc-segment {
        width: 100%;
    }
}