.header {
    position: fixed;
    width: 100%;
    z-index: 10;
    pointer-events: none;
}

.header-div {
    width: 100%;
}

.header-div a {
    line-height: var(--height-logo);
}

.header-dark,
.header-dark div,
.header-dark a,
.header-dark svg {
    color: #111;
    fill: #111;
    transition: color 0.5s, fill 0.5s !important;
}

.header-light,
.header-light div,
.header-light a,
.header-light svg {
    color: #FFFFFF !important;
    fill: #FFFFFF !important;
    transition: color 0.5s, fill 0.5s !important;
}

.header-light,
.header-dark {
    background-color: transparent;
}

.header-dark-bg {
    /*background-color: var(--bs-body-bg);*/
}

.nav-links {
    /*align-self: flex-start;*/
    pointer-events: auto;
    justify-content: end;
}

.nav-links a {
    margin-left: 24px;
    text-decoration: none;
}

.navbar {
    margin-bottom: 20px;
}

.dropdown-toggle {
    padding: 0 !important;
}

.MuiAppBar-root {
    background-color: transparent !important;
    box-shadow: none !important;
}

Button.nav-item {
    background-color: transparent !important;
    border: none;
    padding: 0;
    color: #333333 !important;
}

.MuiToolbar-root {
    /*min-height: var(--header-height) !important;*/
    min-height: 0 !important;
    /*height: var(--header-height);*/
    padding-left: var(--margin-left-right) !important;
    padding-right: var(--margin-left-right) !important;
    gap: 26px;
    margin-top: var(--margin-left-right);
    /*transform: translateY(-50%);*/
}

.MuiToolbar-root * {
    color: #333333 !important;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
}

.logo {
    position: fixed;
    z-index: 20;
    left: var(--margin-left-right);
    /*top: var(--margin-left-right);*/
    transform: translateY(calc(var(--margin-left-right)));
    /*align-self: flex-start;*/
    /*width: 120px;*/
    height: var(--height-logo);
    transition: height 0.5s, transform 0.5s, color 0.5s, fill 0.5s !important;
}

.logo-last {
    /*font-size: ;*/
    /*width: calc(100% - 2 * var(--margin-left-right)) !important;*/
    max-width: calc(100% - 2 * var(--margin-left-right));
    /*aspect-ratio: 9.17361 / 1;*/
    height: var(--height-big-name);
    /*bottom: 0;*/
    /*top: 100%;*/
    transform: translateY(calc(100vh - (100vw - 2 * var(--margin-left-right)) / var(--logo-ratio) - 24px));
}

.homepage-container {
    padding: 0 !important;
}

.inactive-section {
    font-weight: 400 !important;
}

.active-section {
    font-weight: 700 !important;
}