.video_container {
    background: #111111;
    position: absolute;
    height: 100vh;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100vw;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    z-index: -10;
}

#myVideo {
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.section {
    position: relative;
    /*overflow: hidden;*/
    box-sizing: border-box;
}

.page-container {
}

.page-left {
    gap: 20px;
    position: sticky;
    top: 180px;
    height: auto;
}

.page-title {
    font-size: max(56px, min(7vw, 10.5vh));
    font-weight: 700;
    line-height: 1;
    font-family: var(--font-title), sans-serif;
}

.page-intro {
    gap: 10px;
}

.page-content {
    font-size: min(3.5vw, 5.25vh);
    font-weight: 400;
}

.banner {
    border-top: solid 2px;
    border-bottom: solid 2px;
    border-color: var(--bs-body-color);
    /*margin-top: 100px;*/
    /*margin-bottom: 100px;*/
    /*background: #f4eae8;*/
    background: var(--bs-body-bg);
    overflow: hidden;
}

.banner-content {
    /*transition: transform 0.1s;*/
    font-size: max(56px, min(10vw, 15vh));
    white-space: nowrap;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 1;
    /*color: var(--bs-body-bg) !important;*/
    position: relative;
    font-family: "Fira Sans Condensed", sans-serif;
}

.next-page-banner {
    text-decoration: none;
    color: var(--bs-body-color);
}

.banner:hover {
    background: var(--bs-body-color);
    /*border-color: var(--bs-body-bg);*/
    color: var(--bs-body-bg);
}

.banner:hover .banner-content {
    color: var(--bs-body-bg);
}

.home {
    height: 250vh;
    width: 100%;
}

.home-container {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.home-container * {
    color: white;
}

.home-role {
    width: 100%;
    text-align: left;
    font-size: max(18px, min(3.5vw, 4.8vh));
    font-weight: 600;
    font-family: var(--bs-font-sans-serif), sans-serif;
    line-height: 1.5;
    letter-spacing: max(-0.06vw, -0.10vh);
    padding: 0 var(--margin-left-right);
}

.home-headline {
    width: 100%;
    text-align: left;
    padding: 0 var(--margin-left-right) var(--margin-left-right) var(--margin-left-right);
}

.home-headline span {
    font-size: min(9vw, 13.5vh);
    font-family: var(--bs-font-sans-serif), sans-serif;
    line-height: 0.95;
    letter-spacing: max(-0.2vw, -0.3vh);
}

.home-headline span.font-medium {
    font-weight: 500;
}

.home-headline span.font-light {
    font-weight: 200;
    font-style: italic;
    background-image: url("/public/images/bg6.jpg");
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
}

.home-headline::selection,
.home-headline span.selected {
    position: relative;
    z-index: -1;
    overflow: hidden;
}

.home-headline span.selected::before {
    content: attr(data-text); /* Clone the text */
    position: absolute;
    right: 0;
    color: transparent; /* Text color */
    text-decoration: line-through;
    text-decoration-color: #f7ce3a;
    text-decoration-thickness: min(3vw, 5vh);
    z-index: 1;
    font-size: inherit; /* Inherit font size from the original text */
    overflow: hidden;
    width: var(--stripe-width, 100%); /* Use the CSS variable */
    transform: translateY(4%);
}

.work {
    background: var(--bs-body-bg);
    /*position: relative;*/
}

.work-container {
    gap: calc(1.7vw + 5px);
    padding: 120px 0 80px 0;
}

.work-container * {
    color: var(--bs-body-color);
    border-color: var(--bs-body-color);
}

.work-content {
    gap: 48px;
    flex-wrap: wrap;
}

/*.work-container > .work-item:last-child {*/
/*    border-bottom: solid 1px;*/
/*    padding-bottom: 18px;*/
/*}*/

.work-container > .work-item:last-child .work-left {
    border-bottom: none;
}

/*.last-work {*/
/*    border-bottom: solid 1px;*/
/*    padding-bottom: 288px;*/
/*}*/

.contact {
    height: 100vh;
    /*min-height: 100vh;*/
    max-height: 100vh;
    background: var(--bs-body-bg);
    transition: background-color 0.5s;
    /*position: relative;*/
}

.contact-container {
    background-color: #f7ce3a;
    height: 100%;
    /*padding: 48px var(--margin-left-right) 40px var(--margin-left-right);*/
    padding: var(--margin-left-right);
    color: #111111;
    font-size: 14px;
    font-weight: 500;
}

.contact-quote {
    font-size: max(20px, max(2.5vw, 3.75vh));
    font-weight: 500;
    font-family: "Instrument Serif", "Bacasime Antique", serif;
    line-height: 1;
    letter-spacing: max(-0.1vw, -0.15vh);
}

.contact-quote-text {
    width: 60vw;
    margin-bottom: 50px;
}

.contact-quote-author {
    font-style: italic;
}

.contact-detail {
    /*height: 70px;*/
    /*border-top: solid 1px;*/
    margin-bottom: var(--height-big-name);
    padding-bottom: 12px;
}

.contact-detail a {
    color: var(--bs-body-color);
}

.contact-detail-not-current {
    opacity: 0 !important;
    transition: opacity 0.3s;
}

.contact-detail-current {
    opacity: 100% !important;
    transition: opacity 0.3s 0.5s;
}

.contact-detail-head {
    opacity: 50%;
}

.contact-detail-segment {
    justify-content: end;
}

.contact-detail-segment-center {
    align-items: center;
}

.contact-detail-segment-right {
    align-items: end;
}

.contact-detail-segment-right div {
    justify-content: end;
    text-align: right;
}

/*.next-page {*/
/*    !*position: sticky;*!*/
/*    !*top: 100vh;*!*/
/*    z-index: 20;*/
/*    width: 100%;*/
/*    justify-content: center;*/
/*    !*transform: translateY(-100%);*!*/
/*    height: var(--margin-left-right);*/
/*    padding-top: 4px;*/
/*}*/

/*.next-page-button {*/
/*    text-align: center;*/
/*}*/

/*.next-page-text {*/
/*    text-decoration: none;*/
/*    !*color: #989898;*!*/
/*    color: var(--bs-body-bg);*/
/*    opacity: 70%;*/
/*    font-size: 24px;*/
/*    line-height: var(--margin-left-right);*/
/*}*/

@media (max-width: 680px) {
    :root {
        --margin-left-right: 24px;
        --header-height: 70px;
    }

    .work-container {
        padding: 48px 0 80px 0;
    }

    .contact-detail-segment {
        font-size: 12px;
    }
}