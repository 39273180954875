@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');/*@import url('https://fonts.googleapis.com/css2?family=Bayon&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:ital@0;1&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


:root {
    --bs-body-bg: #f9f3f1 !important;
    /*--bs-body-bg: white !important;*/
    /*--bs-body-bg: #111;*/
    /*--bs-body-bg: #9f9db3;*/
    /*--bs-body-bg: #80c34f;*/

    --bs-body-color: #111;
    /*--bs-body-color: white;*/
    /*--bs-body-color: #777;*/

    /*--bs-primary: rgb(240, 240, 232) !important;*/
    /*--bs-primary: #3612b3 !important;*/
    /*--bs-primary-num: 17, 17, 17;*/
    /*--bs-primary-num: 247, 206, 58;*/
    /*--bs-primary-num: 253, 253, 253;*/
    /*--bs-primary: rgb(var(--bs-primary-num)) !important;*/
    /*--bs-primary-trans: rgba(var(--bs-primary-num), 0) !important;*/
    --bs-primary: #f9f3f1;
    --bs-primary-trans: #f9f3f1;

    --header-height: 100px;
    /*--bs-font-sans-serif: "Helvetica Neue", Inter, system-ui, Avenir, Helvetica, Arial, sans-serif !important;*/
    --bs-font-sans-serif: Inter, "Helvetica Neue", system-ui, Arial, sans-serif !important;
    --font-title: "Bebas Neue", "Bayon", "Helvetica Neue", sans-serif !important;
    --stripe-width: 100%;
    --height-next: 34px;
    --margin-left-right: 24px;
    --logo-ratio: 9.17361;
    --height-logo: 13px;
    --height-big-name: calc((100vw - 2 * var(--margin-left-right)) / var(--logo-ratio));

    overscroll-behavior: none;
}

html,body {
    width: 100%;
    /*height: 100%;*/
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

body {
    /*margin: 0;*/
    /*padding: 0;*/
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*position: fixed;*/
    /*overflow-x: hidden;*/
    /*text-decoration: underline;*/
}

::selection {
    /*background: yellow; */
    /*background: transparent;*/
    /*text-decoration: underline;*/
    /*background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #FFD0AE 50%) !important;*/
    background: #f7ce3a;
    /*text-decoration: underline; !* Only works on elements that have the same decoration properties set already *!*/
    /*-webkit-text-decoration-line: underline;*/
    /*text-decoration-color: #f7ce3a;*/
    /*-webkit-text-decoration-color: #f7ce3a;*/
}

a:link {
    text-decoration: none;
    /*color: var(--bs-body-color);*/
}
a:visited {
    text-decoration: none;
    /*color: var(--bs-body-color);*/
}
a:hover {
    text-decoration: none;
    /*color: var(--bs-body-color);*/
}
a:active {
    text-decoration: none;
    /*color: var(--bs-body-color);*/
}

h1 {
    font-family: var(--font-title), sans-serif !important;
    margin-bottom: 2rem !important;
}

.main-container {
    padding: 12px 48px !important;
    border-radius: 2px;
}

Button {
    background-color: #3B0FBA;
    font-family: var(--font-title), sans-serif !important;
    color: white;
    border: none;
    white-space: nowrap;
    cursor: pointer;
}

.button {
    background-color: #3B0FBA;
    font-family: var(--font-title), sans-serif !important;
    color: white;
    border: none;
    white-space: nowrap;
    cursor: pointer;
}

.button:link {
    text-decoration: none;
}
.button:visited {
    text-decoration: none;
}
.button:hover {
    text-decoration: none;
}
.button:active {
    text-decoration: none;
}

.flex0 {
    display: flex;
    flex: none;
}

.flex1 {
    display: flex;
    flex: 1;
}

.flex2 {
    display: flex;
    flex: 2;
}

.flex3 {
    display: flex;
    flex: 3;
}

.flex4 {
    display: flex;
    flex: 4;
}

.flex5 {
    display: flex;
    flex: 5;
}

.flex_col {
    display: flex;
    flex-direction: column;
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.MuiTypography-h3 {
    font-family: var(--font-title), sans-serif !important;
    /*font-weight: 500 !important;*/
    margin-bottom: 0 !important;
}

.MuiTypography-h4 {
    font-family: var(--font-title), sans-serif !important;
    margin-bottom: 0 !important;
}

.MuiTypography-h5 {
    font-family: var(--font-title), sans-serif !important;
    margin-bottom: 0 !important;
}

.MuiTypography-h6 {
    font-family: var(--font-title), sans-serif !important;
    margin-bottom: 0 !important;
}

.MuiTypography-body1 {
    font-family: var(--bs-font-sans-serif), sans-serif !important;
    text-wrap: pretty;
}

.MuiTypography-body2 {
    font-family: var(--bs-font-sans-serif), sans-serif !important;
    text-wrap: pretty;
}

.MuiCard-root {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.MuiCardContent-root {
    padding: 10px 0 0 0 !important;
}

.MuiList-root {
    background: #d58512 !important;
    font-family: var(--bs-font-sans-serif), sans-serif !important;
}